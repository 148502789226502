<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} academic event` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="academicEvent.title"
                                outlined
                                dense
                                :error="$v.academicEvent.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger"></span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.academicEvent.title.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-select
                                class="form-control"
                                v-model="academicEvent.type"
                                label="Type"
                                outlined
                                :items="types"
                                item-text="title"
                                item-value="value"
                                dense>
                            </v-select>
                        </v-col>

                        <v-col cols="12">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="academicEvent.date"
                                    label="Date" dense outlined
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    disabled
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    disabled
                                    v-model="academicEvent.date"
                                    @input="menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12">
                            Select time
                            <v-switch
                                v-model="academicEvent.select_time"
                            ></v-switch>
                        </v-col>

                        <v-col cols="12" md="6" v-if="academicEvent.select_time" class="mt-3">
                            <v-text-field
                                outlined
                                dense
                                v-model="academicEvent.start_date_time"
                                type="time"
                            >
                                <template
                                    v-slot:label>
                                    Start time <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <!-- <v-datetime-picker
                                outlined
                                dense
                                ref="dateTime"
                                label="Start date time"
                                :text-field-props="textFieldProps"
                                v-model="academicEvent.start_date_time"
                            >
                            </v-datetime-picker> -->
                            <span class="text-danger" v-if="$v.academicEvent.start_date_time.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6" class="mt-3" v-if="academicEvent.select_time">
                            <v-text-field
                                outlined
                                dense
                                v-model="academicEvent.end_date_time"
                                type="time"
                            >
                                <template
                                    v-slot:label>
                                    End time <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <!-- <v-datetime-picker
                                outlined
                                dense
                                ref="dateTime1"
                                label="End date time"
                                :text-field-props="textFieldProps"
                                v-model="academicEvent.end_date_time"
                            >
                            </v-datetime-picker> -->
                            <span class="text-danger" v-if="$v.academicEvent.end_date_time.$error">This information is required</span>
                        </v-col>


                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="academicEvent.is_active" :label="academicEvent.is_active ? 'Active' : 'Inactive'"></v-switch>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>

                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('academic-event', 'create') || checkIsAccessible('academic-event', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import AcademicEventService from "@/core/services/academic-event/AcademicEventService";

const academicEvent = new AcademicEventService();

export default {
    validations: {
        academicEvent: {
            title: { required },
            type: { required },
            start_date_time: {
                required: requiredIf(function(nestedModal){
                    return (this.academicEvent.select_time);
                })
            },
            end_date_time: {
                required: requiredIf(function(nestedModal){
                    return (this.academicEvent.select_time);
                })
            },
            is_active: {},
            academic_calendar_id: {},
            select_time: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            academicEvent: {
                title: '',
                type: 'event',
                date: '',
                start_date_time: '',
                end_date_time: '',
                is_active: true,
                academic_calendar_id: '',
                select_time: false,
            },
            textFieldProps:{
                outlined: true,
                dense: true,
            },
            types: [
                {
                    title: 'Event',
                    value: 'event'
                },
                {
                    title: 'Public holiday',
                    value: 'public_holiday'
                },
                {
                    title: 'Meeting',
                    value: 'meeting'
                },
                {
                    title: 'Submitted assignment',
                    value: 'assignment'
                },
            ],
            menu: false,
            academicCalendarId: null,
        };
    },
    methods: {
        showModal(date, academicCalendarId) {
            if (date) {
                this.edit = false
                this.academicEvent.date = date;
                this.academicEvent.academic_calendar_id = academicCalendarId;
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        // getAcademicEvent(id) {
        //     academicEvent
        //     .show(id)
        //     .then(response => {
        //         this.academicEvent = response.data.academicEvent;

        //         if(response.data.academicEvent.start_date_time_formatted){
        //             let entryTimeStamp = Date.parse(this.academicEvent.start_date_time_formatted);
        //             let entryDateTimeObject = new Date(entryTimeStamp);
        //             this.academicEvent.start_date_time = entryDateTimeObject;
        //         }
        //         if(response.data.academicEvent.end_date_time_formatted){
        //             let exitTimeStamp = Date.parse(this.academicEvent.end_date_time_formatted);
        //             let exitDateTimeObject = new Date(exitTimeStamp);
        //             this.academicEvent.end_date_time = exitDateTimeObject;
        //         }
        //     })
        //     .catch(err => {
        //     })
        //     .finally(() => {
        //     })
        // },
        createOrUpdate() {
            this.$v.academicEvent.$touch();
            if (this.$v.academicEvent.$error) {
                setTimeout(() => {
                    this.$v.academicEvent.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateAcademicEvent();
                } else {
                    this.createAcademicEvent();
                }
            }
        },
        updateAcademicEvent() {
            this.isBusy = true;

            // this.formatEntryDate();
            // this.formatExitDate();

            academicEvent
            .update(this.academicEvent.id, this.academicEvent)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },

        createAcademicEvent() {
            this.isBusy = true;
            academicEvent
            .store(this.academicEvent)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$emit("refresh");
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.academicEvent.$reset();
            if(this.academicEvent.selected_time){
                this.$refs.dateTime.date = '';
                this.$refs.dateTime1.date = '';
            }
            this.edit = false;
            this.academicEvent = {
                title: '',
                type: 'event',
                date: '',
                start_date_time: '',
                end_date_time: '',
                is_active: true,
                academic_calendar_id: '',
                select_time: false,
            };
            this.hideModal();
        },
        formatEntryDate(){
            if(this.academicEvent.start_date_time){
                var fullDate = new Date(this.academicEvent.start_date_time);
                const year = fullDate.getFullYear();
                const month = parseInt(fullDate.getMonth())+parseInt(1);
                const day = fullDate.getDate();
                const hour = fullDate.getHours();
                const minute = fullDate.getMinutes();

                const entryDateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+'00';
                this.academicEvent.start_date_time = null;
                this.academicEvent.start_date_time = entryDateTime;
            }
            return;
        },

        formatExitDate(){
            if(this.academicEvent.end_date_time){
                var fullDate = new Date(this.academicEvent.end_date_time);
                const year = fullDate.getFullYear();
                const month = parseInt(fullDate.getMonth())+parseInt(1);
                const day = fullDate.getDate();
                const hour = fullDate.getHours();
                const minute = fullDate.getMinutes();

                const exitDateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+'00';
                this.academicEvent.end_date_time = null;
                this.academicEvent.end_date_time = exitDateTime;
            }
            return ;
        }
    }
}
</script>
