<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Academic calendar - {{ academicCalendar.title }}
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link :to="{ name: 'dashboard' }">
                    Dashboard \ Academic calendar \
                  </router-link>
                  {{ academicCalendar.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col>
                <v-sheet height="64">
                  <v-toolbar
                      flat
                  >
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                    >
                      Today
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                    >
                      <v-icon small>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                    >
                      <v-icon small>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu
                        bottom
                        right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            outlined
                            color="grey darken-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right>
                            mdi-menu-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                          <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                      ref="calendar"
                      class="text-center"
                      v-model="value"
                      color="primary"
                      :events="items"
                      :event-color="getEventColor"
                      :type="type"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="addEvent"
                      @change="getAcademicCalendarEvents"
                  >
                  </v-calendar>
                  <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      offset-x
                  >
                    <v-card
                        color="grey lighten-4"
                        min-width="350px"
                        flat
                    >
                      <v-toolbar
                          :color="selectedEvent.color"
                          dark
                      >
                        <v-btn icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                          <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text>
                        <span v-html="selectedEvent.details"></span>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                            text
                            class="btn-secondary"
                            @click="selectedOpen = false"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>

    <create-or-update-event
        ref="create-update-event"
        @refresh="updateCalendar"
    ></create-or-update-event>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import CreateOrUpdateEvent from "./CreateOrUpdateEvent.vue";
import AcademicCalendarEventService from "@/core/services/academic-calendar-event/AcademicCalendarEventService";
import AcademicCalendarService from "@/core/services/academic-calendar/AcademicCalendarService";

const academicCalendarEvent = new AcademicCalendarEventService();
const academicCalendar = new AcademicCalendarService();

export default {
  name: "ViewCalendar",
  components:{CreateOrUpdateEvent},
  data() {
        return {
          events: [],
          value: '',
          type: 'month',
          selectedEvent: {},
          selectedElement: null,
          selectedOpen: false,
          colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
          academicCalendar: {},
          search: {
            date_type: 'month',
            min_date: '',
            max_date: ''
          },
          typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
          },
        };
    },
  validations: {
    academicCalendarEvent: {
      academic_calendar_id: {required},
      academic_event_ids: {},
    }
  },
  mounted(){
    this.$refs.calendar.checkChange();
    this.getAcademicCalendar();
  },
  computed:{
    academicCalendarId(){
      return this.$route.params.academicCalendarId;
    },
    items() {
      const items = [];
      this.events.forEach((item) => {
        items.push({
          id: item.id,
          name: item.title,
          start: new Date(item.start_date),
          end: new Date(item.end_date),
          type: item.type,
          color: item.color,
          timed: item.start_from || item.end_to,
        })
      })
      return items;
    },
  },
  methods: {
    updateCalendar() {
      this.getAcademicCalendarEvents()
    },

    getAcademicCalendar(){
      academicCalendar
          .show(this.academicCalendarId)
          .then((res) => {
            this.academicCalendar = res.data.academicCalendar;
            this.assignAcademiCalendarId()
          })
          .catch(() => {});
    },

    getAcademicCalendarEvents(event = null){
      if(event) {
        this.search.date_type = this.type;
        this.search.min_date = event.start.date;
        this.search.max_date = event.end.date;
      }
      academicCalendarEvent
          .getByAcademicCalendar(this.academicCalendarId, this.search)
          .then((res) => {
            this.events = res.data.data;
          }).catch(() => {});
    },

    addEvent(clickedDate){
      if(clickedDate.date){
        this.searchDate = clickedDate.date;
      }else{
        this.searchDate = clickedDate;
      }
      this.$refs['create-update-event'].showModal(this.searchDate, this.academicCalendarId)
    },
    viewDay ({ date }) {
      this.value = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.value = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
}
</script>
